<!-- 跳转小程序 -->
<template>
  <div>

  </div>
</template>
<script>
import Api from '@/components/Api/index'
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  data() {
    return {
      h5OpenId: ''
    }
  },
  computed: {
    ...mapState("collection",["schemeUrl"])
  },
  methods: {
    ...mapMutations("collection",["SET_SCHEME_URL"]),
    getUserInfo() {
      let encodedUrl = window.location.href
      // 缓存url
      this.SET_SCHEME_URL(encodedUrl)
      new Api("user").authorizationCallbackAddressH5Transmit("-1", location.href).then(res => {
        window.open(res.data.replaceAll("amp;",""), '_self')
      })
    },
    getWxAppletUrl() {
      console.log(this.schemeUrl,'时刻打开打开看');
      let obj = {
        "jump_wxa": {
          "path": "collectionPages/planCollection/planCollection",
          "query": `url=${encodeURIComponent(this.schemeUrl)}&h5OpenId=${this.h5OpenId}`,
          // 开发环境  正式版为release，体验版为trial，开发版为develop
          "env_version":'release'
        },
        "expire_time": "",
        "expire_type": "",
        "expire_interval": "",
      }
      new Api("user").schemeGenerate(obj).then(res => {
        if (res.status == 200) {
          console.log("schemeGenerate",res.data)
          location.href = res.data
        } else {
          Toast(res.statusText);
        }
      })
    },
    getUrlParams(encodedUrl) {
      let decodedUrl = decodeURIComponent(encodedUrl);
      let questionMarkIndex = decodedUrl.indexOf('=');
      if (questionMarkIndex === -1) {
        return '';
      }
      return decodedUrl.slice(questionMarkIndex + 1);
    }
  },
  mounted() {
    this.h5OpenId = this.$route.query.openid
    if (this.h5OpenId) {
      this.getWxAppletUrl()
    } else {
      this.getUserInfo()
    }
  },
}
</script>
<style lang='scss' scoped></style>